@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700|Roboto:400,700&subset=latin-ext');
@import './normalize.less';
@import './reset.less';
@import './variables.less';
@import './general.less';

.roboto {font-family: 'Roboto', sans-serif;}
.montserrat {font-family: 'Montserrat', sans-serif;}

body {
    .roboto;
}

h1, h2, h3, h4 {
    .montserrat;
    font-weight: 400;
    color: @dark;
}

#content-wrapper {
    position: relative;
}

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 2;

    #logo {
        float: left;
        color: @dark;
        .montserrat;
        font-size: 30px;
    }
    nav {
        float: right;
        .montserrat;
        a {
            display: inline-block;
            padding: 0 15px;
            font-size: 14px;
            letter-spacing: 0.3px;
            color: @dark;
            &:hover {
                color: @white;
            }
        }
    }
    @media all and (max-width: 1400px) {
        padding: 15px 0;
    }
    @media all and (max-width: 1100px) {
        padding: 10px 0;
    }
    @media all and (max-width: 800px) {
        nav {
            a {
                &:hover {
                    color: @red;
                }
            }
        }
    }
    @media all and (max-width: 680px) {
        #logo {
            float: none;
        }
        nav {
            margin-top: 15px;
            float: none;
            a {
                padding: 0;
                margin-right: 20px;
                font-size: 18px;
            }
        }
    }
    @media all and (max-width: 500px) {
        nav a {
            margin-right: 8px;
            font-size: 15px;
        }
    }
}

* {
    box-sizing: border-box;
}

#intro {
    .white-half {
        .col-6;
        height: 700px;
        background-color: #f4f4f4;
        background-image: url('/assets/intro-bg-left.jpg');
        background-repeat: no-repeat;
        background-position: bottom right;
        text-align: right;
        padding-top: 450px;
        padding-right: 80px;
        background-size: 520px auto;
        h1 {
            font-size: 59px;
            line-height: 1.1;
            margin: 0 0 20px 0;
        }
        p {
            .montserrat;
            font-size: 25px;
            font-weight: 700;
            margin: 0;
        }
        @media all and (max-width: 1620px) {
            height: 650px;
            padding-top: 400px;
        }
        @media all and (max-width: 1400px) {
            height: 530px;
            padding-top: 330px;
            background-size: 420px auto;
            h1 {
                font-size: 49px;
            }
        }
        @media all and (max-width: 1100px) {
            height: 500px;
            padding-top: 320px;
            padding-right: 50px;
            background-size: 370px auto;
            h1 {
                font-size: 35px;
                margin: 0 0 10px 0;
            }
            p {
                font-size: 21px;
            }
        }
        @media all and (max-width: 800px) {
            .col-12;
            height: auto;
            padding-top: 160px;
            padding-bottom: 120px;
            padding-left: 60px;
            text-align: left;
            background-size: 310px auto;
        }
        @media all and (max-width: 600px) {
            padding-top: 120px;
            padding-bottom: 90px;
            padding-left: 2.5%;
            background-size: 220px auto;
            h1 {
                font-size: 29px;
            }
            p {
                font-size: 17px;
            }
        }
    }
    .red-half {
        .col-6;
        position: relative;
        overflow: hidden; 
        height: 700px;
        background-color: @red;
        background-image: url('/assets/intro-bg-right.jpg');
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: cover;
        padding-top: 520px;
        padding-left: 80px;
        #thingies {
            position: absolute;
            bottom: 15%;
            left: 5%;
            width: 60%;
            opacity: 0.4;
            @media all and (max-width: 800px) {
                display: none;
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 515px;
            left: 0;
            width: 70px;
            height: 70px;
            background: #f4f4f4;
            transform: translateX(-43px) rotate(45deg);
        }
        .contact-button {
            outline: 0;
            background: rgba(0, 0, 0, 0.3);
            position: relative;
            z-index: 3;
            text-transform: uppercase;
            color: white;
            font-weight: 700;
            font-size: 30px;
            letter-spacing: 2px;
            border: 4px solid white;
            padding: 10px 35px;
            border-radius: 60px;
            line-height: 1;
            display: inline-block;
            .transition(300ms, background);
            &:hover {
                background: @dark;
            }
        }
        @media all and (max-width: 1620px) {
            height: 650px;
            padding-top: 470px;
            &:before {
                top: 465px;
            }
        }
        @media all and (max-width: 1400px) {
            height: 530px;
            padding-top: 420px;
            &:before {
                top: 415px;
            }
        }
        @media all and (max-width: 1100px) {
            height: 500px;
            padding-top: 360px;
            .contact-button {
                font-size: 25px;
            }
            &:before {
                top: 350px;
            }
        }
        @media all and (max-width: 800px) {
            .col-12;
            height: auto;
            padding: 40px 0;
            background-size: cover;
            background-position: center;
            text-align: center;
            .contact-button {
                background: @dark;
            }
            &:before {
                display: none;
            }
        }
        @media all and (max-width: 600px) {
            .contact-button {
                font-size: 20px;
            }
        }
    }
}

.icon {
    width: 50px;
    height: 50px;
    display: block;
    background-image: url('/assets/icons.png');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: auto 50px;
    &.cursor {
        background-position: 0px 0px;
    }
    &.time {
        background-position: -50px 0;
    }
    &.check {
        background-position: -100px 0;
    }
    &.thumb {
        background-position: -150px 0;
    }
    &.forward {
        background-position: -200px 0;
    }
    &.point {
        background-position: -250px 0;
    }
    &.mail {
        background-position: -300px 0;
    }
    &.phone {
        background-position: -350px 0;
    }
}

#pros {
    color: @white;
    background: @dark;
    .row;
    .col-4 {
        padding: 40px 50px;
        border-right: 1px solid rgba(255,255,255,0.1);
        &:nth-child(3) {
            border-right: 0;
        }
        .icon {
            margin-bottom: 10px;
        }
        @media all and (max-width: 1000px) {
            padding: 30px 20px;
        }
        @media all and (max-width: 750px) {
            .col-12;
            border-right: 0;
            display: flex;
            align-items: center;
            padding: 20px 0;
            .icon {
                min-width: 50px;
                margin-bottom: 0;
                margin-right: 20px;
            }
        }
    }
}

#sluzby {
    .heading {
        max-width: 560px;
        margin-top: 100px;
        margin-bottom: 40px;
        h2 {
            margin: 0 0 20px 0;
            line-height: 1.1;
            font-size: 40px;
        }
        p {
            font-size: 25px;
            line-height: 1.1;
        }
        @media all and (max-width: 1620px) {
            margin-top: 80px;
        }
        @media all and (max-width: 1400px) {
            margin-top: 70px;
            margin-bottom: 30px;
            h2 {
                font-size: 35px;
            }
            p {
                font-size: 21px;
            }
        }
        @media all and (max-width: 1000px) {
            margin-top: 50px;
            max-width: 440px;
            h2 {
                font-size: 29px;
                margin: 0 0 10px 0;
            }
        }
    }
    .left-col {
        .col-5;
        color: black;
        position: relative;
        top: 0px;
        text-align: left;
        padding-right: 20px;
        margin-bottom: 40px;
        li {
            padding-top: 26px;
            margin-bottom: 8px;
            position: relative;
            font-size: 18px;
            &:after {
                content: "+";
                position: absolute;
                top: 0;
                left: -20px;
                font-size: 30px;
                height: 30px;
                width: 30px;
                line-height: 30px;
                text-align: center;
                color: black;
                .montserrat;
            }
        }
        @media all and (max-width: 1200px) {
            padding-right: 0;
            li {
                font-size: 16px;
            }
        }
        @media all and (max-width: 1000px) {
            li {
                font-size: 14px;
                padding-top: 20px;
                &:after {
                    top: 0;
                    left: -15px;
                    font-size: 26px;
                    height: 20px;
                    width: 20px;
                    line-height: 20px;
                }
            }
        }
        @media all and (max-width: 750px) {
            .col-4;
        }
        @media all and (max-width: 580px) {
            float: none;
            width: 100%;
            background-image: url('/assets/bulb.png');
            background-position: right bottom;
            background-size: contain;
            background-repeat: no-repeat;
            color: black;
            li {
                padding-top: 10px;
                padding-left: 35px;
                &:after {
                    left: 0px;
                    font-size: 26px;
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                }
            }
        }
    }
    .middle-col {
        .col-3;
        padding: 0 30px;
        img {
            max-width: 100%;
        }
        @media all and (max-width: 750px) {
            padding: 20px;
        }
        @media all and (max-width: 580px) {
            display: none;
        }
    }
    .right-col {
        .col-4;
        color: #727272;
        position: relative;
        top: -100px;
        padding-left: 20px;
        color: black;
        @media all and (max-width: 1000px) {
            top: -80px;
            padding-left: 0;
        }
        li {
            padding-top: 30px;
            margin-bottom: 8px;
            position: relative;
            font-size: 18px;
            padding-left: 15px;
            &:after {
                content: "+";
                .montserrat;
                position: absolute;
                top: 0px;
                left: -5px;
                font-size: 30px;
                height: 30px;
                width: 30px;
                line-height: 30px;
                text-align: center;
                color: black;
            }
        }
        @media all and (max-width: 1200px) {
            li {
                font-size: 16px;
            }
        }
        @media all and (max-width: 750px) {
            .col-5;
            top: 0;
            li {
                padding-top: 25px;
            }
        }
        @media all and (max-width: 580px) {
            float: none;
            width: 100%;
            li {
                padding-top: 10px;
                padding-left: 35px;
            }
        }
    }
    .further {
        text-align: center;
        margin-top: 100px;
        margin-bottom: 100px;
        @media all and (max-width: 1620px) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
        @media all and (max-width: 1400px) {
            margin-top: 60px;
            margin-bottom: 60px;
        }
        @media all and (max-width: 1000px) {
            margin-top: 20px;
            margin-bottom: 40px;
        }
        h3 {
            font-weight: 700;
            font-size: 22px;
            margin: 0 0 20px 0;
        }
        ul {
            .row;
            li {
                position: relative;
                .col-3;
                text-align: left;
                padding: 40px 20px 0 20px;
                font-size: 15px;
                .montserrat;
                &:after {
                    content: "+";
                    .montserrat;
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 25px;
                    height: 25px;
                    width: 25px;
                    line-height: 25px;
                    text-align: center;
                    color: black;
                    transform: rotate(0deg) ;
                    .transition();
                }
                &:hover:after {
                    transform: scale(1.5);
                }
            }
        }
        @media all and (max-width: 580px) {
            h3 {
                text-align: left;
            }
            ul li {
                float: none;
                width: 100%;
                padding: 10px 10px 0 30px;
                margin-bottom: 5px;
            }
        }
    }
}

#o-nas {
    background-color: @dark;
    background-image: url('/assets/about-bg.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: white;
    padding: 150px 0 150px 0;
    @media all and (max-width: 1620px) {
        padding: 120px 0 120px 0;
    }
    @media all and (max-width: 1000px) {
        padding: 120px 0 120px 0;
    }
    @media all and (max-width: 750px) {
        padding: 80px 0 80px 0;
    }
    h2 {
        color: white;
        font-weight: 600;
        font-size: 32px;
        margin: 0 0 40px 0;
    }
    p {
        margin-bottom: 10px;
        color: rgba(255,255,252,0.9);
    }
    .about-bullets {
        .row;
        margin-top: 80px;
        max-width: 800px;
        li {
            .col-4;
            color: white;
            padding-right: 20px;
            h3 {
                color: white;
                margin: 0;
                font-size: 21px;
                @media all and (max-width: 750px) {
                    font-size: 18px;
                }
            }
            @media all and (max-width: 470px) {
                .col-12;
                .row;
                .icon {
                    float: left;
                    margin-right: 20px;
                }
            }
        }
        @media all and (max-width: 750px) {
            margin-top: 50px;
            max-width: 100%;
        }
    }
}

#reference {
    text-align: center;
    background: #F4F4F4;
    padding: 120px 0;
    h2 {
        font-size: 40px;
        font-weight: 600;
        @media all and (max-width: 700px) {
            font-size: 33px;
            margin: 10px 0;
        }
    }
    .references {
        margin: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        li {
            width: 100%/4;
            padding: 0 10px;
            float: left;
            text-align: center;
            line-height: 80px;
            margin-bottom: 20px;
            img {
                vertical-align: middle;
                max-width: 100%;
                max-height: 80px;
            }
            @media all and (max-width: 700px) {
                width: 50%;
            }
        }
    }
    .clients {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        li {
            display: flex;
            position: relative;
            text-align: left;
            padding: 15px 10px 0 10px;
            font-weight: 300;
            margin: 10px 10px;
            font-size: 15px;
            .montserrat;
            @media all and (max-width: 500px) {
                flex-basis: 100%;
                margin: 0;
            }
            &:before {
                content: "+";
                position: relative;
                font-size: 17px;
                height: 17px;
                width: 17px;
                line-height: 17px;
                text-align: center;
                color: black;
                font-family: monospace;
                transition: 300ms all;
            }
            &:hover:before {
                transform: scale(1.5);
            }
        }
    }
    @media all and (max-width: 1200px) {
        padding: 60px 0;
    }
    @media all and (max-width: 1000px) {
        padding: 40px 0;
    }
}

#kontakt {
    padding: 120px 0;
    background: @dark;
    color: rgba(255,255,255,0.8);
    @media all and (max-width: 1200px) {
        padding: 80px 0;
    }
    @media all and (max-width: 1000px) {
        padding: 50px 0;
    }
    .contact-panel {
        .col-7;
        p {
            max-width: 500px;
            font-size: 18px;
            &.margbot {
                margin-bottom: 50px;
            }
        }
        @media all and (max-width: 700px) {
            .col-12;
        }
    }
    h2 {
        font-size: 40px;
        font-weight: 600;
        color: white;
        margin: 0 0 10px 0;
        @media all and (max-width: 1000px) {
            font-size: 35px;
        }
    }
    h3 {
        margin: 10px 0 20px 0;
        color: white;
        font-weight: 600;
        font-size: 21px;
    }
    .contacts {
        .row;
        margin: 30px 0;
        .icon {
            display: inline-block;
        }
        a {
            .montserrat;
            color: white;
            font-size: 21px;
            display: inline-block;
            vertical-align: top;
            line-height: 50px;
            font-weight: 600;
        }
        @media all and (max-width: 1200px) {
            a {
                font-size: 17px;
            }
        }
        @media all and (max-width: 1000px) {
            margin: 20px 0;
            .col-6 {
                .col-12;
                a {
                    font-size: 19px;
                }
            }
        }
    }
    .people {
        .row;
        color: rgba(255,255,255,0.4);
        a {
            color: rgba(255,255,255,0.4);
            font-size: 13px;
        }
        address {
            font-style: normal;
        }
        @media all and (max-width: 1000px) {
            .col-4 {
                .col-12;
                margin-bottom: 10px;
            }
        }
    }
    #contact-form {
        padding-left: 30px;
        .col-5;
        input, select, textarea {
            width: 100%;
            color: @dark;
            .montserrat;
            font-weight: 300;
            outline: none;
            border: 1px solid #E9E9E9;
            box-shadow: none;
            border-radius: 1px;
            padding: 8px 12px;
        }
        .form-row {
            .row;
            margin-bottom: 5px;
            .col-6:nth-child(1) {padding-right: 2px;}
            .col-6:nth-child(2) {padding-left: 2px;}
            p{
                text-align: center;
                a{
                    color: @color1;
                    &:hover{
                        color: @color1 - #444444;
                    };
                }
            }
        }
        input[type="submit"] {
            .montserrat;
            outline: none;
            margin: 20px auto 0 auto;
            display: block;
            background: transparent;
            text-transform: uppercase;
            color: #c7c7c7;
            border: 2px solid #c7c7c7;
            padding: 6px 20px;
            line-height: 1;
            border-radius: 30px;
            font-size: 15px;
            letter-spacing: 0.5px;
        }
        @media all and (max-width: 1000px) {
            padding-left: 15px;
        }
        @media all and (max-width: 700px) {
            .col-12;
            padding-left: 0px;
        }
    }
}

#signature {
    background: @dark;
    text-align: right;
    font-size: 13px;
    color: rgba(255,255,255,0.4);
    a {
        color: rgba(255,255,255,0.4);
        &:hover {
            color: rgba(255,255,255,1);
        }
    }
}

#cont-form {
    transition: 300ms opacity;
}
#cookies {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background: #fff;
    box-sizing: border-box;
    z-index: 9999;
    text-align: center;
    padding: 0 5%;
    border-top: 2px black solid;
    p {
        margin: 5px 0;
        font-size: 0.7rem;
    }
    a {
        font-size: 0.7rem;
        color: @color1;
        &:hover{
            color: @color1 - #444444;
        };
    }
    button {
        font-size: 0.7rem;
    }
    .button {
        background: @color1;
        &:hover{
            color: @color1 - #444444;
        };
        color: #000;
        padding: 2px 5px;
        margin-left: 10px;
        border: 1px solid #444;
        margin-top: 5px;
    }
}
.info-personal, .info-cookies{
    padding-top: 115px;
    padding-bottom: 80px;
    .banner{
        min-height: unset;
        h1{
            padding: 10px 0px;
            line-height: 1;
            display: block;
            box-sizing: border-box;
        }
    }
    .inner-content{
        a{
            color: @color1;
            &:hover{
                color: @color1 - #444444;
            };
        }
        ul{
            margin-top: 8px; margin-bottom: 8px;
            li{
                list-style-type: disc;
                list-style-position: inside;
                margin-top: 3px; margin-bottom: 3px;
                margin-left: 20px;
            }
        }
    }
}