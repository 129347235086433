// Your color palette
@color1: #F4352B;
@color2: #202020;
@color3: #000;
@color4: #000;
@color-bg: #fff;

@white: #fff;
@black: #000;
@dark: @color2;
@red: @color1;
@blue: @color2;


//----------------------------------- MIXINS -------------------------------------

.transition(@time: 200ms, @property: all, @style: ease, @delay: 0ms) {
    -webkit-transition: @time, @property, @style, @delay;
    -moz-transition: @time, @property, @style, @delay;
    -ms-transition: @time, @property, @style, @delay;
    transition: @time, @property, @style, @delay;
}

.round-borders(@border-radius: 5px) {
    border-radius: @border-radius;
}

.xxl-font {

    font-size: 23px;
}

.xl-font {

    font-size: 21px;
}

.lg-font {

    font-size: 19px;
}

.md-font {

    font-size: 17px;
}

.sm-font {

    font-size: 15px;
}

.xs-font {

    font-size: 13px;
}

.xxs-font {

    font-size: 11px;
}

//-----------------------------------LAYOUT HELPERS----------------------------------
@small: 15px;
@medium: 35px;
@large: 50px;

.sm-margin-top {

    margin-top: @small;
}

.md-margin-top {

    margin-top: @medium;
}

.lg-margin-top {

    margin-top: @large;
}

.sm-margin-bottom {

    margin-bottom: @small;
}

.md-margin-bottom {
    margin-bottom: @medium;
}

.lg-margin-bottom {
    margin-bottom: @large;
}

//---------------------------------ANIMATIONS----------------------------------------

// Example
@-webkit-keyframes bounce {

    0%   { height: 0px;  }
    25%  { height: 15px; }
    50%  { height: 0px;  }
    75%  { height: 15px; }
    100% { height: 0px;  }
}

@-moz-keyframes bounce {

    0%   { height: 0px;  }
    25%  { height: 15px; }
    50%  { height: 0px;  }
    75%  { height: 15px; }
    100% { height: 0px;  }
}

@-o-keyframes bounce {

    0%   { height: 0px;  }
    25%  { height: 15px; }
    50%  { height: 0px;  }
    75%  { height: 15px; }
    100% { height: 0px;  }
}

@keyframes bounce {

    0%   { height: 0px;  }
    25%  { height: 15px; }
    50%  { height: 0px;  }
    75%  { height: 15px; }
    100% { height: 0px;  }
}

.bounce {

    -webkit-animation: bounce 1s 1; /* Safari 4+ */
       -moz-animation: bounce 1s 1; /* Fx 5+ */
         -o-animation: bounce 1s 1; /* Opera 12+ */
            animation: bounce 1s 1; /* IE 10+, Fx 29+ */

    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}
