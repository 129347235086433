hr {
    border: none;
    height: 1px;
    background: @color1;
}

::selection {
  background: @color1;
  color: @white;
}
::-moz-selection {
  background: @color1;
  color: @white;
}

::-webkit-input-placeholder { /* WebKit browsers */
    color:   #aaa;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #aaa;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #aaa;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
   color:    #aaa;
}

// GENERAL
// - often used classes, edit to suite your needs

.row {
    &:after {
          content: "";
          display: table;
          clear: both;
    }
}

.inner-content {
    width: 1140px;
    margin: 0 auto;
    @media all and (max-width: 1200px) {
        width: 950px;
    }
    @media all and (max-width: 1000px) {
        width: 720px;
    }
    @media all and (max-width: 750px) {
        width: 95%;
    }
}

.center-content {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.col-12 {
    width: 100%;
}

.one-half, .col-6 {
    .pull-left;
    width: 100%/2;
}

.one-third, .col-4 {
    .pull-left;
    width: 100%/3;
}

.two-thirds, .col-8 {
    .pull-left;
    width: 100%/3*2;
}

.one-quarter, .col-3 {
    .pull-left;
    width: 100%/4;
}

.three-quarters, .col-9 {
    .pull-left;
    width: 100%/4*3;
}
.col-7 {
    .pull-left;
    width: 100%/12*7;
}
.col-5 {
    .pull-left;
    width: 100%/12*5;
}
.col-2 {
    .pull-left;
    width: 100%/6;
}
.col-1 {
    .pull-left;
    width: 100%/12;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.clearfix {
        clear: both;
}

[ui-view] {

    &.ng-enter {

         -webkit-transition:200ms linear all 200ms;
                 transition:200ms linear all 200ms;
    }
    &.ng-leave {

         -webkit-transition:200ms linear all;
                 transition:200ms linear all;
    }
    &.ng-enter {
        opacity: 0;
    }
    &.ng-enter-active {
        opacity: 1;
    }
    &.ng-leave {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    &.ng-leave-active {
        opacity: 0;
    }
}

#splash-screen {

    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,1);
    top: 0;
    left: 0;

    .loading-panel {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -140px;
        margin-left: -140px;
        width: 280px;
        height: 280px;
        .center-content;
        border-radius: 8px;

        background: @white;
        color: @color2;

        img {
            width: 30%;
            margin: 50px 0;
        }

        span {

            display: block;
            font-size: 35px;
        }
    }
}

.growl, .confirmation {

    position: fixed;
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: 0;
    left: 0;
    margin-left: 0;
    border-radius: 0px;
    z-index: -1;
    opacity: 0;
    -webkit-transition: 250ms opacity;
    transition: 250ms opacity;

    &.active {
        z-index: 9999;
        opacity: 1;
    }


    .growl-wrapper, .confirmation-wrapper {
        position: absolute;
        min-height: 160px;
        width: 320px;
        left: 50%;
        background: #fff;
        border-radius: 5px;
        top: 50%;
        margin-left: -160px;
        margin-top: -80px;
    }
    &.warning .growl-wrapper {
        background: #EFEFEF;
        box-shadow: 0 0 25px #FF9B00;
    }
    &.error .growl-wrapper {
        background: #EFEFEF;
        box-shadow: 0 0 25px #CB1F00;
    }
    &.info {
        background: rgba(255, 255, 255, 0);
        width: 320px;
        height: auto;
        top: auto;
        bottom: 30px;
        margin-top: 0;
        right: 20px;
        left: auto;
        .growl-wrapper {
              position: relative;
              min-height: 0;
              left: auto;
              top: auto;
              margin-left: 0;
              margin-top: 0px;
              box-shadow: 2px 3px 10px -1px #0081ff;
        }
    }

    .growl-content, .confirmation-content {
        text-align: center;
        padding: 30px 40px;
    }

    .buttons {
        .row;
        text-align: center;
        button {
            display: inline-block;
            color: @white;
            background: @color4;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            padding: 7px 15px;
            margin: 0 5px 8px 5px;
            border-radius: 4px;
            line-height: 1;
        }
    }
}

#cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    background: #dedede;
    font-size: 12px;
    box-sizing: border-box;
    z-index: 9999;
    .center-content;
    p {
        margin: 5px 0;
    }
    .button {
        background: #888;
        color: white;
        padding: 2px 5px;
        margin-left: 10px;
        border: none;
        font-size: 12px;
    }
    .inner-content {
        width: 95% !important;
    }
}


.no-highlight {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -ms-user-select: none; /* IE10+ */
    user-select: none; /* webkit (konqueror) browsers */
}
