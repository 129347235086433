a {
    text-decoration: none;
    &:focus {
        outline: 0;
    }
}

ul {
    list-style-type: none;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-padding-start: 0;
    margin-top: 0;
    // Firefox
    padding-left: 0;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
}

p {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-padding-start: 0;
}

dd, dt {
    -webkit-margin-start: 0;
}
